/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import Img from 'gatsby-image'
import { format } from 'date-fns'
import { Link } from 'gatsby'

import Tag from 'src/components/tag'
import ClockIcon from 'src/assets/svgs/clock'
import EyeIcon from 'src/assets/svgs/eye'
import formatExerpt from 'src/utils/formatExerpt'

const Card = ({
  slug,
  image,
  title,
  tags,
  date,
  timeToRead,
  views,
  excerpt,
}) => {
  const { theme, colorMode } = useThemeUI()

  return (
    <article
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <div
        sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, px: 4, py: 3 }}
      >
        {tags.map((tag) => (
          <Tag
            key={tag.name}
            name={tag.name}
            slug={tag.slug}
            color={tag.description}
          />
        ))}
      </div>
      <Link
        to={`/${slug}/`}
        title={title}
        sx={{
          display: 'block',
          height: '100%',
          overflow: 'hidden',
          borderRadius: 0,
          bg: 'backgroundCard',
          boxShadow:
            colorMode === 'dark'
              ? 'none'
              : '0px 2px 8px rgba(92, 99, 105, 0.15)',
          textDecoration: 'none',
        }}
      >
        {image.childImageSharp ? (
          <Img
            fluid={image.childImageSharp.fluid}
            sx={{ width: '100%', height: '55%' }}
          />
        ) : (
          <div
            sx={{
              width: '100%',
              height: '55%',
              background: `url(${image.publicURL}) center center / cover`,
            }}
          />
        )}
        <div
          sx={{
            height: '45%',
            overflow: 'hidden',
            px: 3,
            py: 4,
          }}
        >
          <div
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              color: 'text',
            }}
          >
            <h2
              sx={{
                mb: 2,
                fontSize: [2, 3],
                color: 'text',
              }}
            >
              {title}
            </h2>

            <span
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                fontSize: 0,
              }}
            >
              {format(new Date(date), 'dd.MM.yy')}
              <ClockIcon color={theme.colors.text} sx={{ ml: 2, mr: 1 }} />
              {Math.round(timeToRead / 2)} мин
              <EyeIcon color={theme.colors.text} sx={{ ml: 2, mr: 1 }} />
              {views}
            </span>

            <p sx={{ fontSize: 0 }}>{formatExerpt(excerpt)}</p>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Card
