/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

const Tag = ({ name, slug, color }) => {
  return (
    <Link
      to={`/category/${slug}/`}
      title={name}
      sx={{
        variant: 'links.textOnDark',
        display: 'inline-block',
        mr: 2,
        px: 3,
        py: 1,
        borderRadius: '80px',
        background: color ? color : '#1DA1F2',
        fontSize: '10px',
        fontWeight: 'bold',
      }}
    >
      {name}
    </Link>
  )
}

export default Tag
