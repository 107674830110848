const formatExcerpt = (excerpt) => {
  const cleanExcerpt = excerpt
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/\s{2,}/g, ' ')
    .replace(/\s+$/, '')
  const shortExcerpt =
    cleanExcerpt.length > 90
      ? `${cleanExcerpt.substring(0, 90)}...`
      : cleanExcerpt

  return shortExcerpt
}

export default formatExcerpt
