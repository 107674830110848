import * as React from 'react'

function SvgComponent({ color = '#fff', ...props }) {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M8.77 2.204c-3.626 0-6.575 2.824-6.575 6.296 0 3.472 2.95 6.296 6.575 6.296s6.574-2.824 6.574-6.296c0-3.472-2.95-6.296-6.574-6.296zm0 11.333c-2.9 0-5.26-2.26-5.26-5.037s2.36-5.037 5.26-5.037 5.26 2.26 5.26 5.037-2.36 5.037-5.26 5.037z"
        fill={color}
      />
      <path d="M9.427 5.352H8.112V9.13h3.945V7.87h-2.63V5.352z" fill={color} />
    </svg>
  )
}

export default SvgComponent
