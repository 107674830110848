import * as React from 'react'

function SvgComponent({ color = '#fff', ...props }) {
  return (
    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" {...props}>
      <path
        d="M17.204 8.928c-1.643 3.316-4.127 4.984-7.457 4.984-3.332 0-5.815-1.668-7.458-4.985a.966.966 0 010-.855c1.643-3.316 4.127-4.984 7.458-4.984 3.331 0 5.814 1.668 7.457 4.985a.963.963 0 010 .855zM9.747 4.283c-2.797 0-4.844 1.358-6.288 4.217 1.444 2.859 3.491 4.217 6.288 4.217 2.796 0 4.843-1.358 6.287-4.217-1.442-2.859-3.49-4.217-6.287-4.217zm-.07 7.139c-1.685 0-3.05-1.308-3.05-2.922s1.365-2.922 3.05-2.922c1.685 0 3.051 1.308 3.051 2.922s-1.366 2.922-3.05 2.922zm0-4.781c-1.073 0-1.941.831-1.941 1.859s.868 1.86 1.941 1.86 1.942-.832 1.942-1.86-.869-1.86-1.942-1.86z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
